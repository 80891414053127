import TextInput from '@/Components/FormFields/TextInput';
import FieldGroup from '@/Components/Forms/FieldGroup';
import InputError from '@/Components/Forms/InputError';
import InputLabel from '@/Components/Forms/InputLabel';
import PrimaryAuthButton from '@/Components/PrimaryAuthButton';
import GuestLayout from '@/Layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';
import { FormEventHandler, ReactElement, useEffect } from 'react';

type InertiaData = {
  status?: string;
  canResetPassword: boolean;
};

export default function Login({ status, canResetPassword }: InertiaData) {
  const { data, setData, post, processing, errors, reset } = useForm({
    email: '',
    password: '',
    remember: false,
  });

  useEffect(() => {
    return () => {
      reset('password');
    };
  }, []);

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    post(route('login'));
  };

  return (
    <>
      <Head title="Log in" />

      {status && <div className="text-sm font-medium text-accent">{status}</div>}

      <form onSubmit={submit} className="flex flex-col gap-12">
        <FieldGroup>
          <InputLabel htmlFor="email" value="Email Address" light />

          <TextInput
            id="email"
            type="email"
            name="email"
            value={data.email}
            autoComplete="username"
            underline
            autoFocus
            onChange={(e) => setData('email', e.target.value)}
          />

          <InputError message={errors.email} light />
        </FieldGroup>

        <FieldGroup>
          <InputLabel htmlFor="password" value="Password" light />

          <TextInput
            id="password"
            type="password"
            name="password"
            value={data.password}
            autoComplete="current-password"
            underline
            onChange={(e) => setData('password', e.target.value)}
          />

          <InputError message={errors.password} className="mt-2" />
        </FieldGroup>

        <div>
          <PrimaryAuthButton disabled={processing}>Sign in</PrimaryAuthButton>
          {canResetPassword && (
            <div className="mt-8">
              <Link
                href={route('password.request')}
                className="rounded-md text-xs text-gray-50 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Lost your password?
              </Link>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

Login.layout = (page: ReactElement<InertiaData>) => <GuestLayout>{page}</GuestLayout>;
